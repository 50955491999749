import logo from "./logo.svg";
import {
  AccessDeniedException,
  BedrockRuntimeClient,
  InvokeModelCommand,
} from "@aws-sdk/client-bedrock-runtime";
import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { uploadData, getUrl, list } from "aws-amplify/storage";
import mclogo from "./mclogo.png";
import {
  ListBucketsCommand,
  S3Client,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import {
  AppflowClient,
  ListFlowsCommand,
  StartFlowCommand,
  DescribeFlowCommand,
  DescribeFlowExecutionRecordsCommand,
} from "@aws-sdk/client-appflow";
import {
  Button,
  Message,
  Menu,
  Flex,
  Input,
  Label,
  MenuItem,
  Heading,
  Text,
  Tabs,
  Divider,
  Collection,
  TextField,
  View,
  Loader,
  withAuthenticator,
  Image,
  useTheme,
  Grid,
  Card,
} from "@aws-amplify/ui-react";
import { signOut } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import { listExtractModels, getExtractModel } from "./graphql/queries";

const client = generateClient();

function App() {
  const [files, setFiles] = useState([]);
  const [extModel, setextModel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [florid, setFlowid] = useState(false);
  const [showBackup, setshowBackup] = useState(false);
  const [record, setrecord] = useState(null);
  const [bytesize, setbytesize] = useState(null);
  const [csv, setcsv] = useState(null);
  const [inpText, setinpText] = useState("");
  const [returnResult, setReturnResult] = useState("");

  React.useEffect(() => {
    // declare the data fetching function
    // sf();
    //getObjects();
    //getExtractModel();
  }, [setextModel]);

  const getObjects = async () => {
    try {
      const result = await list();
      console.log(result);
      setFiles(result.items);
    } catch (error) {
      console.log(error);
    }
  };

  const clientAppFLow = new AppflowClient({
    region: "eu-west-2",
    credentials: {
      accessKeyId: process.env.REACT_APP_accessKey,
      secretAccessKey: process.env.REACT_APP_secretKey,
    },
  });

  const lastBackup = async () => {
    const s3Client = new S3Client({
      region: "eu-west-2",
      credentials: {
        accessKeyId: process.env.REACT_APP_accessKey,
        secretAccessKey: process.env.REACT_APP_secretKey,
      },
    });

    // download csv file
    const params = {
      /** input parameters */
      Bucket: "mcsalesforce",
      Key: "Mc-App/7f19dd58-5003-31f4-8b10-eda3bbd5fd37/-108956001-2024-04-07T16:24:36",
    };

    const commands3 = new GetObjectCommand(params);
    try {
      const response = await s3Client.send(commands3);
      console.log("Buckets: ", response);
      // The Body object also has 'transformToByteArray' and 'transformToWebStream' methods.
      const str = await response.Body.transformToString();
      setcsv(str);
    } catch (error) {
      console.error("Erro MC", error);
    }
  };

  const runFlow = async () => {
    setLoading(true);
    const input = {
      flowName: "Mc-App",
    };
    const command = new StartFlowCommand(input);
    const commandDescribed = new StartFlowCommand(clientAppFLow);
    try {
      const { executionId, flowStatus, flowArn } = await clientAppFLow.send(
        command
      );
      setFlowid(executionId);
      // process data.
      console.log("AppFlow RUN", executionId);
    } catch (error) {
      console.log("appflow Erro ", error);
      // error handling.
    } finally {
      setLoading(false);
      // finally.
    }
  };

  const getBackupInfo = async () => {
    setLoading(true);
    const input = {
      // DescribeFlowExecutionRecordsRequest
      flowName: "Mc-App", // required
    };

    const commandDescribed = new DescribeFlowExecutionRecordsCommand(input);
    //const commandDesc = new DescribeFlowCommand(input);
    try {
      const desc1 = await clientAppFLow.send(commandDescribed);
      //const desc2 = await clientAppFLow.send(commandDesc);
      const { bytesWritten, recordsProcessed } =
        desc1.flowExecutions[0].executionResult;

      setbytesize(bytesWritten);
      setrecord(recordsProcessed);

      console.log("AppFlow RUN 2 ", bytesWritten);
      console.log("AppFlow RUN 2 ", recordsProcessed);
      setshowBackup(true);
    } catch (error) {
      console.log("appflow Erro ", error);
      // error handling.
    } finally {
      setLoading(false);
      // finally.
    }
  };

  const sf = async () => {
    console.log("appflow2");
    const params = {
      /** input parameters */
    };
    const command = new ListFlowsCommand(params);
    try {
      const data = await clientAppFLow.send(command);
      // process data.
      console.log("AppFlow", data);
    } catch (error) {
      console.log("appflow Erro ", error);
      // error handling.
    } finally {
      // finally.
    }
  };

  const getExtractModel = async () => {
    // List all items
    setLoading(true);
    let allExtractModel = await client.graphql({
      query: listExtractModels,
    });
    let mappedArray = allExtractModel.data.listExtractModels.items.filter(
      (item) => item
    );

    // mappedArray.forEach((element) => {
    //   console.log("aa" + JSON.parse(JSON.stringify(element.extractText)));
    //   console.log("bb" + JSON.parse(JSON.stringify(element.extractTextJson)));
    // });
    setextModel(mappedArray);
    setLoading(false);
    //console.log(mappedArray);
  };

  const inputTextHandler = (e) => {
    //console.log(e.currentTarget.value);
    setinpText(e.currentTarget.value);
  };

  const resetResult = () => {
    setReturnResult(null);
    setinpText(null);
  };

  const invokeBedrockAgent = async (sessionId) => {
    const client = new BedrockRuntimeClient({
      region: "eu-west-3",
      credentials: {
        accessKeyId: process.env.REACT_APP_accessKey,
        secretAccessKey: process.env.REACT_APP_secretKey,
      },
    });

    const modelId = "amazon.titan-text-express-v1";
    // const modelId = "amazon.titan-embed-image-v1";

    // const prompttext = {
    //   inputText: inpText,
    // };

    const prompttext =
      '{"inputText":"Question: ' +
      inpText +
      '\\n","textGenerationConfig":{"maxTokenCount":512,"stopSequences":["User:"],"temperature":0,"topP":1}}';

    const command = new InvokeModelCommand({
      modelId: modelId,
      contentType: "application/json",
      accept: "application/json",
      body: prompttext,
    });

    try {
      const response = await client.send(command);
      //console.log(response);
      const decodedResponseBody = new TextDecoder().decode(response.body);

      /** @type {ResponseBody} */
      const responseBody = JSON.parse(decodedResponseBody);
      //console.log(responseBody);

      setReturnResult(responseBody.results[0].outputText);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Tabs.Container defaultValue="Tab 1">
      <Tabs.List>
        <Tabs.Item value="Tab 1">Extract AI</Tabs.Item>
        <Tabs.Item value="Tab 2">Salesforce</Tabs.Item>
        <Tabs.Item value="Tab 3">Generative AI</Tabs.Item>
      </Tabs.List>
      <Tabs.Panel value="Tab 1">
        <View className="App" backgroundColor="rgb(232,232,232)">
          {showBackup && (
            <Message
              variation="filled"
              colorTheme="info"
              heading="Last backup info"
            >
              <span>
                Size of csv file: {bytesize} bytes - Record processed: {record}
                <Button
                  margin={10}
                  width={10}
                  size="small"
                  onClick={() => setshowBackup(false)}
                >
                  X
                </Button>
              </span>
            </Message>
          )}
          <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns="1fr 12fr 12fr"
            templateRows="1fr"
            margin={10}
          >
            <Menu columnStart="1" columnEnd="1" menuAlign="start">
              <MenuItem onClick={signOut}>Sign Out</MenuItem>
              <MenuItem onClick={getExtractModel}>Refresh</MenuItem>
              <Divider />
              <MenuItem isDisabled>Delete</MenuItem>
              <MenuItem isDisabled>Settings</MenuItem>
            </Menu>

            <Image
              rowSpan={1}
              columnStart="2"
              columnEnd="2"
              alt="Mastercomp Logo"
              src={mclogo}
              backgroundColor="initial"
              opacity="100%"
              height={50}
            />
          </Grid>

          <Card>
            <StorageManager
              acceptedFileTypes={["*"]}
              accessLevel="guest"
              maxFileCount={1}
              isResumable
            />
            {loading && <Loader size="large" variation="linear" />}
          </Card>

          <Grid templateColumns="1fr" templateRows="10rem 10rem" gap="small">
            {/* <View backgroundColor="lightgrey">
          <Collection
            items={files}
            type="list"
            direction="row"
            gap="10px"
            wrap="nowrap"
          >
            {(item, index) => (
              <Card
                key={index}
                borderRadius="medium"
                maxWidth="20rem"
                variation="outlined"
                margin="small"
              >
                {item.key}
              </Card>
            )}
          </Collection>
        </View> */}
            <View>
              <Collection
                items={extModel}
                type="list"
                direction="column"
                gap="10px"
                wrap="nowrap"
              >
                {(item, index) => (
                  <Card
                    key={index}
                    textAlign="left"
                    borderRadius="medium"
                    variation="outlined"
                    margin="small"
                  >
                    {item.id} : {item.fileName} : {item.extractText}
                  </Card>
                )}
              </Collection>
            </View>
          </Grid>
        </View>
      </Tabs.Panel>
      <Tabs.Panel value="Tab 2">
        <View className="App" backgroundColor="rgb(232,232,232)">
          {showBackup && (
            <Message
              variation="filled"
              colorTheme="info"
              heading="Last backup info"
            >
              <span>
                Size of csv file: {bytesize} bytes - Record processed: {record}
                <Button
                  margin={10}
                  width={10}
                  size="small"
                  onClick={() => setshowBackup(false)}
                >
                  X
                </Button>
              </span>
            </Message>
          )}
          <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns="1fr 12fr 12fr"
            templateRows="1fr"
            margin={10}
          >
            <Menu columnStart="1" columnEnd="1" menuAlign="start">
              <MenuItem onClick={signOut}>Sign Out</MenuItem>
              <Divider />
              <MenuItem onClick={runFlow}>Salesforce Backup</MenuItem>
              <MenuItem onClick={getBackupInfo}>Backup Info</MenuItem>
              <MenuItem onClick={lastBackup}>Download last backup</MenuItem>
            </Menu>

            <Image
              rowSpan={1}
              columnStart="2"
              columnEnd="2"
              alt="Mastercomp Logo"
              src={mclogo}
              backgroundColor="initial"
              opacity="100%"
              height={50}
            />
          </Grid>
          {csv && <div>{csv}</div>}
        </View>
      </Tabs.Panel>

      <Tabs.Panel value="Tab 3">
        <View className="App" backgroundColor="rgb(232,232,232)">
          <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns="1fr 12fr 12fr"
            templateRows="1fr"
            margin={10}
          >
            <Menu columnStart="1" columnEnd="1" menuAlign="start">
              <MenuItem onClick={signOut}>Sign Out</MenuItem>
              <Divider />
            </Menu>
          </Grid>
          <center>
            <Label margin={10} htmlFor="small">
              Generative AI
            </Label>
            <Input
              id="genai"
              type="text"
              isRequired
              size="small"
              width="50%"
              onChange={inputTextHandler}
            />
          </center>
          <Button
            margin={10}
            width={100}
            size="small"
            onClick={() => invokeBedrockAgent("sesion id")}
          >
            Get AI
          </Button>
          <Button margin={10} width={200} size="small" onClick={resetResult}>
            Reset result
          </Button>

          {returnResult && <div>{returnResult}</div>}
        </View>
      </Tabs.Panel>
    </Tabs.Container>
  );
}

export default withAuthenticator(App, { hideSignUp: true });

// const [selectFile, setSelectFile] = useState(null);

// const upload = async () => {
//   try {
//     const result = await uploadData({
//       key: "photos/test.txt",
//       data: "file",
//     }).result;
//     console.log("Succeeded: ", result);
//   } catch (error) {
//     console.log("Error : ", error);
//   }
// };

// const downloadObject = async () => {
//   const getUrlResult = await getUrl({
//     key: "filename",
//   });
// };

// const handleFileInput = (e) => {
//   setSelectFile(e.target.files[0]);
// };

// <center>
//   <input type="file" onChange={handleFileInput}></input>
//   <button onClick={() => upload(selectFile)}>Upload Document</button>
// </center>
