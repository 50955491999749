/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getExtractModel = /* GraphQL */ `
  query GetExtractModel($id: ID!) {
    getExtractModel(id: $id) {
      id
      extractTextJson
      extractText
      bucketName
      timestamp
      fileName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listExtractModels = /* GraphQL */ `
  query ListExtractModels(
    $filter: ModelExtractModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtractModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        extractTextJson
        extractText
        bucketName
        timestamp
        fileName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
